import { Injectable ,Component, OnDestroy, ViewChild } from '@angular/core';
import { OwlDateTimeComponent } from 'ng-pick-datetime/date-time/date-time-picker.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService  {
  @ViewChild(OwlDateTimeComponent, {static : false}) dateTimePicker: OwlDateTimeComponent<any>;
  private user = new BehaviorSubject(null);
  private subMenus = new BehaviorSubject(null);
  private datePicker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private date: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private smallScreenMenuState: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private expireTrips = new BehaviorSubject(null);
  private markedTripsReady = new BehaviorSubject(null);
  private dispatchApproachingTrips = new BehaviorSubject(null);
  private drivers = new BehaviorSubject(null);
  private assign = new BehaviorSubject(null);
  private isLoggedIn = new BehaviorSubject(false);
  private driver = new BehaviorSubject(null);
  private signupRequests = new BehaviorSubject(null);
  private assignedJob = new BehaviorSubject(null);


  constructor() { }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  saveUser(user) {
    this.user.next(user);
  }

  getSubMenus() {
    return this.subMenus.asObservable();
  }

  saveSubMenus(subMenus) {
    this.subMenus.next(subMenus);
  }

  toggleDatePicker(bool){
    this.datePicker.next(bool);
  }

  getDatePickerSate(){
    return this.datePicker.asObservable();
  }

  setDate(date){
    this.date.next(date);
  }

  getDate(){
    return this.date.asObservable();
  }

  getSmallScreenMenuState(): Observable<any> {
    return this.smallScreenMenuState.asObservable();
  }

  setSmallScreenMenuState(b) {
    this.smallScreenMenuState.next(b);
  }

  addExpireTripsListener(){
    return this.expireTrips.asObservable();
  }

  getMarkReadyTripsListener(){
    return  this.markedTripsReady.asObservable();
  }

  getDispatchApproachingTripsListener(){
    return this.dispatchApproachingTrips.asObservable();
  }

  geteMarkReadyStatus(){
    return this.assign;
  }

  getDriverLocation() {
    return this.drivers;
  }

  updateDrivers(drivers: any) {
    this.drivers.next(drivers);
  }

  getDrivers() {
    return this.drivers.asObservable();
  }

  getLoginStatus() {
    return this.isLoggedIn;
  }
  
  updateDriverLocation(driver: any){
    this.driver.next(driver);
  }

  updateMarkReadyStatus(data: any){
    // console.log("mark ready status update")
    this.assign.next(data.assign);
  }

  addMarkedTripsReadyTrips(data){
    this.markedTripsReady.next(data);
  }

  addDispatchApproachingTrips(data){
    this.dispatchApproachingTrips.next(data);
  }

  addExpireTrips(data){
    this.expireTrips.next(data);
  }

  addSignupRequests(data) {
    this.signupRequests.next(data);
  }

  
  updateAssignedJob(data: any){
    let assign = data && data.assign ? data.assign : undefined;
    this.assignedJob.next(assign);
  }
}
