import Swal from 'sweetalert2';
function sweetAlertTwoButtons(title, text, type, confirmButtonText, cancelButtonText?) {
    const swalFormat = {
        title,
        text,
        type,
        showCancelButton: cancelButtonText ? true : false,
        confirmButtonColor: cancelButtonText ? '#d33' : '#3085d6',
        cancelButtonColor: '#3085d6',
        confirmButtonText,
        cancelButtonText

    };
    return swalFormat;
    // return Swal.fire(
    //     swalFormat
    //   );
}

function sweetAlertOneButtons(title, text, type, confirmButtonText) {
    const swalFormat = {
        title,
        text,
        type,
        confirmButtonColor: type === 'warning' || type === 'error' ? '#d33' : '#3085d6',
        confirmButtonText

    };
    return swalFormat;
    // return Swal.fire(
    //     swalFormat
    //   );
}

export function sweetAlert(title, text, type, confirmButtonText, cancelButtonText?) {
    if (arguments.length === 4) {
        return Swal.fire(
            sweetAlertOneButtons(title, text, type, confirmButtonText)
        );
    } else if (arguments.length === 5) {
        return Swal.fire(
            sweetAlertTwoButtons(title, text, type, confirmButtonText, cancelButtonText)
        );
    }
}

