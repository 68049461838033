import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-trip-actions-dropdown',
  templateUrl: './trip-actions-dropdown.component.html',
  styleUrls: ['./trip-actions-dropdown.component.css']
})
export class TripActionsDropdownComponent implements OnInit, OnChanges {
  @Input() assign;

  @Output() markReady = new EventEmitter<any>();
  @Output() markVip = new EventEmitter<any>();
  @Output() markConfirmed = new EventEmitter<any>();
  @Output() markVoicemail = new EventEmitter<any>();
  @Output() tripDetails = new EventEmitter<any>();
  isClose = false;

  constructor() { }
  
  ngOnInit() {
    
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    
  }

  isStringExist(substrings, str) {
    if (substrings.some(v => str.toLowerCase().includes(v))) {
      return true;
    }
    return false;
  }
}
