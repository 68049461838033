import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CircleTimerModule } from '@flxng/circle-timer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FilterByKeyPipe } from './pipe/list-filterbykey-pipe/list-filterbykey-pipe';
import { AgmCoreModule } from '@agm/core';
import { TokenInterceptorService } from './service/token-interceptor-service/token-interceptor.service';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
// we are using this in edit profile for all time zone list
import { TimezonePickerModule } from 'ng2-timezone-selector';
// import { TripsHistoryComponent } from './component/dispatch-center/trips-history/trips-history.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule, OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime-moment';
import { GooglePlaceDirective } from './directives/google-places-directive/google-place.directive';
import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { AgmOverlays } from 'agm-overlays';
import { AgmDirectionModule } from 'agm-direction';
import { TimeagoModule } from 'ngx-timeago';
import {
  NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig,
  SPINNER, POSITION, PB_DIRECTION
} from 'ngx-ui-loader';
import { SideNavComponent } from './components/shared/layout/side-nav/side-nav.component';
import { HeaderComponent } from './components/shared/layout/header/header.component';
import { OutsideClickDirective } from './directives/outside-click-directive/outside-click.directive';
import { ToastrModule } from 'ngx-toastr';
import { AuthTokenComponent } from './components/auth-token/auth-token.component';
import { ClockTimePipe } from './pipe/clock-time/clock-time.pipe';
import { CamelCasePipe } from './pipe/camelcase.filter/camelcase.filter.pipe';
import { FilterPipe } from './pipe/list-filter-pipe/list-filter-pipe';
import { SortByDatePipe } from './pipe/sort-by-date-pipe/sort-by-date.pipe';
import { HomeComponent } from './components/home/home.component';
import { TripActionsDropdownComponent } from './components/shared/trip-actions-dropdown/trip-actions-dropdown.component';
import { DatePipe } from './pipe/date-pipe/date.pipe';
import { SocketService } from './service/socket-service/socket-service.service';
import { MySocketWrapper } from './service/socket-service/socket-wrapper';
import {NgPipesModule} from 'ngx-pipes';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopperDirective } from './directives/popper-tooltip/popper.directive';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { CreateTripComponent } from './components/trips/create-trip/create-trip.component';
import { AllTripsComponent } from './components/trips/all-trips/all-trips.component';
import { LogsComponent } from './components/logs/logs.component';
import { TripDetailModalComponent } from './components/modals/trip-detail-modal/trip-detail-modal.component';
import { EditTripModalComponent } from './components/trips/edit-trip-modal/edit-trip-modal.component';
import { CompanyNoteModalComponent } from './components/modals/notes/company-note-modal/company-note-modal.component';
import { NotificationsModalComponent } from './components/modals/notifications-modal/notifications-modal.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig  = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 80,
  bgsType: 'ball-spin-clockwise',
  blur: 0,
  fgsColor: '#727cf5',
  fgsPosition: 'center-center',
  fgsSize: 0,
  fgsType: 'ball-spin',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 150,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: '#ffffff',
  pbColor: '#727cf5',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

export const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HHmm',
  parseInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignInComponent,
    ForgotPasswordComponent,
    FilterByKeyPipe,
    GooglePlaceDirective,
    SideNavComponent,
    OutsideClickDirective,
    AuthTokenComponent,
    ClockTimePipe,
    CamelCasePipe,
    FilterPipe,
    SortByDatePipe,
    HomeComponent,
    TripActionsDropdownComponent,
    DatePipe,
    NotFoundComponent,
    PopperDirective,
    UserProfileComponent,
    CreateTripComponent,
    AllTripsComponent,
    LogsComponent,
    TripDetailModalComponent,
    EditTripModalComponent,
    CompanyNoteModalComponent,
    NotificationsModalComponent,
    ],

  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgPipesModule,
    TimeagoModule.forRoot(),
    HttpClientModule, // import HttpClientModule
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({ exclude:['http://provider.qalbs.com/api/assigns/dashboard', 'http://provider.qalbs.com/api/notifications', 'http://provider.qalbs.com/api/assigns/corporate',
    'http://provider.qalbs.com/api/users/me',  'http://provider.qalbs.com/api/drivers/nearby'],
     showForeground: true }),
     CircleTimerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNoxWXagcapR0tH-bxnJFPK28y_Oro2O0',
      libraries: ['places']
    }),
    SocketIoModule,
    AgmDirectionModule,
    TimezonePickerModule,
    BrowserAnimationsModule,

    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AgmOverlays,
    ToastrModule.forRoot({
    timeOut: 5000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
    progressBar:true
    }),
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot()
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    { provide: AuthorizationGuard, useClass: AuthorizationGuard },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },

    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    MySocketWrapper,
    SocketService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
