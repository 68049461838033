import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from 'src/app/utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient
  ) { }

  getAllTrips(search) {

    let options = { params: search };
    
    return this.http.get(urls.BASE_URL + "assigns/trips", options);
  }
  getDashboardData() {
    return this.http.get(urls.BASE_URL + "facilitytrips/dashboard");
  }

  getDashboardTrips(payload) {
    return this.http.post(urls.BASE_URL + "assigns/trips", payload);
  }

  


  sendEmail(payload){
    var formData = new FormData();

    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    return this.http.post("\mail.php", formData);
  }
}
