import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AuthService } from './service/auth-service/auth.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { Events } from './utils/event-utils';
import { SharedDataService } from './service/shared-service/shared-data.service';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  title = 'nemt-angular8';
  state;
  getState: Observable<any>;
  myRoute = "login";
  DateTimeAdapter;
  // loader = true;
  user;



  constructor(
    private authService: AuthService,
    private router: Router,
    dateTimeAdapter: DateTimeAdapter<any>,
    private sharedDataService: SharedDataService
  ) {
    this.routerListener();
    this.DateTimeAdapter = dateTimeAdapter;
    this.DateTimeAdapter.setLocale('en-US');

  }


  ngOnInit() {
     this.sharedDataService
      .getUser()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.isLoggedIn = true;
          
          moment.tz.setDefault(user.timeZone);
         
        } else {
          this.isLoggedIn = false;
         
        }
      });
       if (!this.user) {
      this.verifyToken();
    }

  }

  verifyToken() {
    // if (this.state.user === null && this.state.token !== null) {
    //   this.store.dispatch(new VerifyToken());
    // }

    this.authService
      .VerifyToken()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.user) {
          this.sharedDataService.saveUser(data.user);
        }
      });
  }

  routerListener() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        
        // if (event.url === '/assignstrips/inprogress') {
        //   this.loader = false;
        // } else {
        //   this.loader = true;
        // }
        if (this.authService.getToken('token')) {
          this.isLoggedIn = true;
          if (event.url === '/login' || event.url === '/forgotPassword') {
            this.router.navigateByUrl('/');
          }
        } else if (this.authService.getToken('token') == null) {
          this.isLoggedIn = false;
          
          if (event.url === '/forgotpassword') {
            
            // this.router.navigateByUrl('forgotPassword');
            this.myRoute = 'forgotPassword';
          } else if (event.url === '/login') {
            
            this.myRoute = 'login';
          } else {

            const myNewURL = event.url.split('/');
            
            if (myNewURL.length && myNewURL[1] === 'auth-token') {
              
              this.myRoute = 'auth-token';
            } else {
              
              this.myRoute = 'login';
            }


          }


        } else {
          this.isLoggedIn = true;
        }
      }
    });
  }
  ngOnDestroy(): void {
  }

}




