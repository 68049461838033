import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthorizationGuard } from './service/auth-guard/authorization-guard.service';
import { AuthTokenComponent } from './components/auth-token/auth-token.component';
import { HomeComponent } from './components/home/home.component';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { CreateTripComponent } from './components/trips/create-trip/create-trip.component';
import { AllTripsComponent } from './components/trips/all-trips/all-trips.component';
import { LogsComponent } from './components/logs/logs.component';


const routes: Routes = [
  { path: 'login', component: SignInComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  // Dispatch Center
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthorizationGuard] },

  { path: 'auth-token/:token', component: AuthTokenComponent },

  // Operations
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizationGuard] },

  // trips
  // { path: 'trips', redirectTo: 'trips/outstanding', pathMatch: 'full' },
{ path: 'trips', component: AllTripsComponent, canActivate: [AuthorizationGuard] },
{ path: 'trips/create', component: CreateTripComponent, canActivate: [AuthorizationGuard] },

  // Profile
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthorizationGuard] },

  // credentialing

  { path: 'credentialing', redirectTo: 'credentialing/companies', pathMatch: 'full' },

  // logs
  { path: 'logs', component: LogsComponent, canActivate: [AuthorizationGuard] },
  // Signup Request

  { path: 'auth-token/:token', component: AuthTokenComponent },
  // Not Found
  { path: '**', component: NotFoundComponent, canActivate: [AuthorizationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
