import { Component, OnInit, Renderer2, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { AuthService } from "../../service/auth-service/auth.service";
import { Router } from "@angular/router";
import { sweetAlert } from "../../utils/swal";
import { SharedDataService } from "src/app/service/shared-service/shared-data.service";
import { SocketService } from "src/app/service/socket-service/socket-service.service";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.css"],
})
export class SignInComponent implements OnInit {
  success: boolean;
  passwordToggleFlag = true;
  signInForm: FormGroup;
  submitted = false;
  roles = "facility";

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private socket: SocketService,
    private router: Router
  ) { }

  ngOnInit() {
    this.signInForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
    });
  }

  onSignIn() {
    this.submitted = true;

    if (this.signInForm.invalid) {
      return;
    }

    const payload = {
      ...this.signInForm.value,
      roles: 'facility'
    }

    this.authService
      .login(payload)
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.success) {
          this.authService.setToken(data.token);
          this.sharedDataService.saveUser(data.user);
          this.socket.connectSocket();
          this.router.navigateByUrl('/');
        } else {
          sweetAlert("Error", data.message, "warning", "OK");
        }
      });
  }

  togglePasswordType() {
    this.passwordToggleFlag = !this.passwordToggleFlag;
  }

  get form() {
    return this.signInForm.controls;
  }

  ngOnDestroy(): void { }
  
}
