import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from '../utils/url-utils';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
constructor(private http: HttpClient) { }
  getlogs() {
    return this.http.get(urls.BASE_URL + "facilitytrips/logs");
  }
}
