import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { LogsService } from 'src/app/service/logs.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.css']
})
export class NotificationsModalComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<any>();
  notifications = [];

  constructor(
    private logService: LogsService
  ) { }

  ngOnInit() {
    this.getNotifications();
  }

  getNotifications() {
    this.logService
      .getlogs()
      .subscribe((data: any) => {
        if (data) {
          this.notifications = data;
        }
      });
  }

  ngOnDestroy(): void {
  }
}
