import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthService } from '../../service/auth-service/auth.service';
import { Observable } from 'rxjs';
import { sweetAlert } from '../../utils/swal';
import { takeUntil } from 'rxjs/operators';
import {componentDestroyed} from '@w11k/ngx-componentdestroyed';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  getState: Observable<any>;
  constructor(private fb: FormBuilder, private authService: AuthService,) {
  }
  forgotPasswordForm: FormGroup;
  submitted = false;
  ngOnInit() {

    // this.getState
    // .pipe(takeUntil(componentDestroyed(this)))
    // .subscribe((state) => {
    //   const errorMessage = state.errorMessage;
    //   if (state.type === AuthActionTypes.FORGET_PASSWORD && errorMessage != null) {
    //     if (state.success) {
    //       sweetAlert('Success', errorMessage, 'success', 'OK');
    //     } else {
    //       sweetAlert('Error', errorMessage, 'error', 'OK');
    //     }
    //   }
    // });

    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  get form() {
    return this.forgotPasswordForm.controls;
  }

  onForgotPassword() {

    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    } else {
      const payload = {
        email: this.forgotPasswordForm.value.email
      };
      this.authService
                .forgetPasswordRequestwithNgx(payload.email)
                .pipe(takeUntil(componentDestroyed(this)))
                .subscribe(data => {
                  if(data){
                  // sweetAlert('Success', 'Password Change Successfully', 'success', 'OK');
                  }
                })
      // this.store.dispatch(new ForgetPassword(payload));
    }
  }

  ngOnDestroy(): void {
  }
}
