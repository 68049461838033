import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-company-note-modal',
  templateUrl: './company-note-modal.component.html',
  styleUrls: ['./company-note-modal.component.css']
})
export class CompanyNoteModalComponent implements OnInit {
  @Input() trip: any;
  @Output() onCloseModal = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
   closeNotesModal(){
    this.onCloseModal.emit();
  }
  

}
