import { Component, OnInit } from '@angular/core';
import { LogsService } from 'src/app/service/logs.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  logsList:any;
  constructor(private logService:LogsService) { }

  ngOnInit() {
    this.getTripLogs()
  }

  getTripLogs(){
    this.logService.getlogs().subscribe(data=>{
      if(data){
        this.logsList = data ;
      }
    })
  }

}
