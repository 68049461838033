import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth-service/auth.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';

@Component({
  selector: 'app-auth-token',
  templateUrl: './auth-token.component.html',
  styleUrls: ['./auth-token.component.css']
})
export class AuthTokenComponent implements OnInit {
  authToken;

  constructor(
    private authService: AuthService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private socket: SocketService,
  ) { }

  ngOnInit() {

    const myNewURL = this.router.url.split('/');
    if (myNewURL.length === 3) {
      setTimeout(() => {
        this.authService.setToken(myNewURL[2]);
        this.verifyToken();
      }, 5000);

    } else {
      this.clearUserData();
    }
  }


  verifyToken() {
    this.authService
      .VerifyToken()
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(data => {
        if (data && data.user) {
          this.sharedDataService.saveUser(data.user);
          this.socket.connectSocket();
          this.router.navigateByUrl('/');
        } else {
          this.clearUserData();
        }
      });
  }


  clearUserData() {
    this.socket.disconnectSocket();
    this.authService.removeToken();
    this.sharedDataService.saveUser(null);
    this.router.navigateByUrl('**');
  }

  ngOnDestroy(): void {
  }

}
