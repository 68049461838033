import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { UpdatePasswordService } from 'src/app/service/update-password.service';
import { imageProcessing } from 'src/app/utils/image-processing-utils';
import { sweetAlert } from 'src/app/utils/swal';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userprofileForm: FormGroup;
  user: any;
  selectedProfileImageFile = null;
  profileImageUrl = null;
  isSubmit = false;
  timeZone = 'America/New_York';

  constructor(
    private updatePasswordService: UpdatePasswordService,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.userprofileForm = this.fb.group({
      displayName: [''],
      email: [''],
      address: [''],
      timeZone: [''],
      password: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]]
    })


    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.user = data;
          this.timeZone = this.user.timeZone
          this.userprofileForm.patchValue({
            ...this.user,
          })
          this.profileImageUrl = this.user.profileImageURL;

        }
      });


  }

  processProfileFile(ImageInput: any) {
    imageProcessing(ImageInput)
      .subscribe((result) => {
        this.selectedProfileImageFile = result.file;
        this.profileImageUrl = result.src;


      });

  }

  get form() {
    return this.userprofileForm.controls;
  }

  uploadImage() {
    const src = this.profileImageUrl;
    const splitArry = src.split("/");
    const fileName = splitArry[splitArry.length - 1].split(".")[0];

    fetch(src)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], fileName, { type: "image/png" })
        this.selectedProfileImageFile = file;
        this.profileImageUrl = src;
      })
    this.updatePasswordService
      .changeProfileImage(this.selectedProfileImageFile)
      .subscribe((data: any) => {
        if (data) {
          if (data.success) {
            sweetAlert('Success', 'Image Updated Successfully', 'success', 'OK');
            this.selectedProfileImageFile = null;
            if (data.user) {
              this.sharedDataService.saveUser(data.user);
            }
          }
        }
      });

  }

  onSubmit() {
    this.isSubmit = true;
    if (this.userprofileForm.invalid) {
      return
    }
    const payload = {
      currentPassword: this.userprofileForm.value.password,
      newPassword: this.userprofileForm.value.newPassword,
      verifyPassword: this.userprofileForm.value.confirmPassword
    }
    this.updatePasswordService.updatePassword(payload).subscribe(data => {
      if (data.success) {
        sweetAlert('Success', 'Password Updated Successfully', 'success', 'OK');
        this.userprofileForm.patchValue({
          password : '',
          newPassword : '',
          confirmPassword: ''
        })
        this.isSubmit = false;
      } else {
        sweetAlert('Error', data.message, 'warning', 'OK');
        // this.userprofileForm.patchValue({
        //   password : '',
        //   newPassword : '',
        //   confirmPassword: ''
        // })
        this.isSubmit = false;
      }
    }, err => {
      sweetAlert('Error', err, 'warning', 'OK');
      // this.userprofileForm.patchValue({
      //   password : '',
      //   newPassword : '',
      //   confirmPassword: ''
      // })
      this.isSubmit = false;
    });
  }
}
