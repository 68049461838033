import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HomeService } from './home-service/home.service';
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { getBrokersList } from 'src/app/utils/utils.common';
import * as moment from 'moment-timezone';
import { Events } from 'src/app/utils/event-utils';
import * as _ from 'underscore';
import { SocketService } from 'src/app/service/socket-service/socket-service.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  dashboardTrips;

  constructor(
    private homeService: HomeService,
    private socketService: SocketService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getDashboardTrips()
  }

  navigateToTrips(status: string) {
    console.log('status', status);
    this.router.navigate(['/trips'], { state: { status : status } });
  }

  getDashboardTrips() {
    this.homeService.getDashboardData().subscribe(data => {
      if (data) {
        this.dashboardTrips = data;
      }
    })
  }

  ngOnDestroy() {
  }


}
