import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from '../utils/url-utils';
import { errorHandl } from '../utils/network-utils';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TripsService {

  constructor(private http: HttpClient) { }

  getTrips(payload) {
    let params = new HttpParams();

    // Add your query parameters
    params = params.set('isShowAllTrips', payload.isShowAllTrips);
    params = params.set('status', payload.status);
    params = params.set('scheduleTime', payload.scheduleTime);

    return this.http.get<any>(urls.BASE_URL + 'facilitytrips', { params })
      .pipe(
        catchError(errorHandl)
      );
  }

  getTripDetailsById(id): Observable<any> {
    return this.http
      .get<any>(
        urls.BASE_URL + urls.GET_TRIP_DETAILS + "/" + id
      )
      .pipe(catchError(errorHandl));
  }

  saveTrip(tripId, payload) {
    const timeKeys = [
      "schedulePickupHour",
      "schedulePickupMinutes",
      "appointmentPickupHour",
      "appointmentPickupMinutes",
      "preScheduleTime",
    ];
    delete payload["companyType"];
    timeKeys.forEach((element) => {
      delete payload[element];
    });

    if (payload.tripLegs && payload.tripLegs.length) {
      payload.tripLegs = payload.tripLegs.splice(1, payload.tripLegs.length);
    }

    if (payload.tripLegs && payload.tripLegs.length === 0) {
      delete payload["tripLegs"];
    } else if (payload.tripLegs && payload.tripLegs.length > 0 && !payload.isMedical) {
      for (let i = 0; i < payload.tripLegs.length; i++) {
        delete payload.tripLegs[i].tripId;
      }
    }

    if (payload.tripLegs && payload.tripLegs.length) {
      payload['additionalOptions'] = "multiLeg";
    } else {
      payload['additionalOptions'] = "singleLeg";
    }
    if (tripId) {
      return this.http.put(urls.BASE_URL + 'facilitytrips/' + tripId, payload)
        .pipe(catchError(errorHandl));
    } else {
      return this.http.post(urls.BASE_URL + 'facilitytrips', payload)
        .pipe(catchError(errorHandl));
    }
  }

  createNewTrip(tripData, tripId?): Observable<any> {
    const timeKeys = [
      "schedulePickupHour",
      "schedulePickupMinutes",
      "appointmentPickupHour",
      "appointmentPickupMinutes",
      "scheduledTime",
    ];
    delete tripData["companyType"]; // not require right now
    timeKeys.forEach((element) => {
      delete tripData[element];
    });
    tripData.tripLegs = tripData.tripLegs.splice(1, tripData.tripLegs.length);
    if (tripData.tripLegs.length === 0) {
      delete tripData["tripLegs"];
    } else if (tripData.tripLegs.length > 0 && !tripData.isMedical) {
      for (let i = 0; i < tripData.tripLegs.length; i++) {
        delete tripData.tripLegs[i].tripId;
      }
    }
    console.log(tripData);
    if (tripId) {
      return this.http
        .put<any>(
          urls.BASE_URL + urls.CREATE_NEW_TRIP_URL + "/" + tripId,
          tripData
        )
        .pipe(catchError(errorHandl));
    } else {
      delete tripData._id;
      return this.http
        .post<any>(urls.BASE_URL + urls.CREATE_NEW_TRIP_URL, tripData)
        .pipe(catchError(errorHandl));
    }
  }
}
