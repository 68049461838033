import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-trip-detail-modal',
  templateUrl: './trip-detail-modal.component.html',
  styleUrls: ['./trip-detail-modal.component.css']
})
export class TripDetailModalComponent implements OnInit {
  @Input() trip;
  @Output() onCloseModal = new EventEmitter<string>();
  origin = { lat: 0.0, lng: 0.0 };
  destination = { lat: 0.0, lng: 0.0 };
  constructor() { }

  ngOnInit() {
    this.setupMap();
    
  }

  setupMap() {
    console.log(this.trip);
    if (this.trip) {
      this.origin = {
        lat: this.trip.jobOriginLatitude,
        lng: this.trip.jobOriginLongitude,
      };
      this.destination = {
        lat: this.trip.jobDestinationLatitude,
        lng: this.trip.jobDestinationLongitude,
      };
    }
  }

  closeModal() {
    this.onCloseModal.emit();
  }

}
