import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urls } from '../utils/url-utils';
import { catchError } from 'rxjs/operators';
import { errorHandl } from '../utils/network-utils';

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordService {

  constructor(private http: HttpClient) { }

  updatePassword(payload) {
    return this.http.post<any>(urls.BASE_URL + 'users/password', payload)
      .pipe(
        catchError(errorHandl)
      );
  }

  changeProfileImage(payload) {
    const formData = new FormData();
    formData.append('newProfilePicture', payload);

    return this.http.post<any>(urls.BASE_URL + 'users/picture', formData)
      .pipe(
        catchError(errorHandl)
      );
  }
}
