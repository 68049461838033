import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import * as moment from "moment-timezone";
import { SharedDataService } from 'src/app/service/shared-service/shared-data.service';
import { TripsService } from 'src/app/service/trips.service';
import { MenuService } from '../../shared/layout/menu-service/menu.service';

@Component({
  selector: 'app-all-trips',
  templateUrl: './all-trips.component.html',
  styleUrls: ['./all-trips.component.css']
})
export class AllTripsComponent implements OnInit {
  isListView = false;
  filteredTrips;
  trips: any = [];
  user: any;
  searchDate = moment().tz("America/New_York").startOf('day');
  tripModalObj;
  subMenus = [];
  status = "outstanding";
  search: any;
  editTripModalObj;
  assignModalObj;
  isFromDashboard = false;

  constructor(
    private tripService: TripsService,
    private menuService: MenuService,
    private sharedDataService: SharedDataService,
    private router: Router
  ) {
    const routeData = this.router.getCurrentNavigation().extras.state;
    if (routeData && routeData.status) {
      this.isFromDashboard = true;
      this.status = routeData.status;
    }
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.sharedDataService
      .getUser()
      .subscribe(data => {
        if (data) {
          this.user = data;
          this.searchDate = moment().tz(this.user.timeZone, true).startOf("day");
          this.getStaffRoles();
        }
      });
  }

  getStaffRoles() {
    if (this.user && this.user.roles) {
      if (typeof this.user.roles === "string") {
        if (this.user.sRoles)
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.sRoles);
        else
          this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles);
      } else if (typeof this.user.roles === "object") {
        this.subMenus = this.menuService.getAssignedSubMenu(this.user.roles[0]);
      }
    }

    if (this.subMenus && this.subMenus.length) {
      if (!this.isFromDashboard) {
        if (this.subMenus[0].value === "all" && this.subMenus.length > 1)
          this.status = this.subMenus[1].value;
        else 
          this.status = this.subMenus[0].value;
      }
      this.getTrips();
    }
  }

  getTrips() {
    this.trips = [];
    const payload = {
      isShowAllTrips: false,
      status: this.status,
      scheduleTime: this.searchDate.toISOString()
    };

    this.tripService
      .getTrips(payload)
      .subscribe(data => {
        if (data) {
          this.trips = data;
        }
      })
  }

  changeView() {
    this.isListView = !this.isListView;
  }

  onSearchDateChange() {
    this.getTrips();
  }

  setTripStatus(status) {
    this.status = status;
    this.getTrips();
  }

  openCompanyModal(trip) {
    this.assignModalObj = trip
  }

  openTripDetail(trip) {
    this.tripModalObj = trip;
  }

  openTripEditModal(trip) {
    this.editTripModalObj = trip._id;
  }

  onCloseModal(evt) {
    if (evt) {
      this.editTripModalObj = null
      this.getTrips();
    } else {
      this.editTripModalObj = null
    }
  }
}
